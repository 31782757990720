const html = require('choo/html')
const {title, slogan, generateStyle, randomFont } = require('./slogans.js')
const AnimatedSlogan = require('./animated-slogan.js')

let renderCount = 1
// border bt bw1




module.exports = (state, emit) => {
  const buyNow = state.cache(AnimatedSlogan, 1).render(3000, ()=> {
    renderCount ++
    const style = generateStyle()
    return html`<div class="w4 pa2 h-100 ba b2 pointer br-pill dim tc ${randomFont()}" onclick=${()=> emit("show info")} style="${style}"> ${renderCount%2  == 0? 'support hydra synth' : 'get yours here!'}  </div>`})
  
  
  const backToGallery = html`<div class="w4 pa2 h-100 ba b2 pointer br-pill dim tc" onclick=${()=> emit("clear sketch")}> back to gallery </div>`

  return html`<div class="w-100 h3 b1 flex fixed bottom-0 left-0" style="background:linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))"
  >
   <div behavior="scroll" direction="left" scrollamount="2" onclick=${()=> emit("show info")} class="pointer dim bottom-0 br-pill tc ba flex-auto" style="font-size:4vw">
      ${title()}

    </div>
    ${state.currentView === "" ? buyNow : backToGallery } 
   
  </div>`
}

//   <--!div class="absolute w-100 ph6" style="margin-top: -30px; pointer-events:none">  ${state.cache(AnimatedSlogan, 1).render()} </div>
//<div class="w5 pa2 ba pointer br-pill dim tc" style="${generateStyle()}">  ${state.cache(AnimatedSlogan, 1).render()}  </div>
// <div class="marquee f1"> UNIQUE MAGICAL BROWSER OBJECTS</div>
//   <div class="marquee2 f1"> UNIQUE MAGICAL BROWSER OBJECTS</div>
// <marquee behavior="scroll" direction="left" scrollamount="2"  class="bottom-0 fixed f1 flex-auto">
//
//     ${title()}
//
// </marquee>
// <div class="marquee w-100 f1"> ${title()}</div>
//   <div class="marquee2 w-100 f1"> ${title()}</div>
