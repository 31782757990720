const html = require('choo/html')
const Iframe = require('./clickable-iframe.js')
const encodeBase64 = (text) => btoa(encodeURIComponent(text))
const {title, slogan } = require('./slogans.js')
const loading = require('./loading.js')
// const hydraURL = (code) => `https://hydra-lofi.glitch.me?code=${encodeBase64(code)}`
// const hydraURL = (code) => `${window.location.origin}/lofi-renderer.html?code=${encodeBase64(code)}`

const rand = (min, max) => min + Math.random() * (max - min)

// ${slogan({ classes:"absolute ph2", styles: "top:-30px;"})}
//   ${slogan({ classes:"absolute ph2", styles: "bottom:-30px;"})}
const showSketches = (sketches, state, emit) => sketches.map((sketch, index) => {
    const w = rand(200, 350)
    // const iframe = html`  <iframe
    //     src=${sketch.url}
    //     class="RELATIVE"
    //     width="${w}px"
    //     height="${w}px"
    //     style="width:${w}px;height:${w}px"
    //
    //   >
    //   </iframe>`
    // console.log('tag',  `collection-${index}`)
    const iframe = state.cache(Iframe, `collection-${index}`).render({
          src: sketch.url,
          class: "relative pointer",
          width: `${w}px`,
          height: `${w}px`,
          style: `width:${w}px;height:${w}px`,
          onclick: () => { emit("select sketch", sketch)}
    })

    //  console.log("iframe", iframe.contentWindow)
      window.iframe = iframe
//  iframe.contentWindow.onclick = () => emit("select sketch", sketch)
  return html`
  <div
    class="dib pa4 relative animated-positions pointer"
    style="top: ${rand(-20, 20)}px; left: ${rand(-10, 40)}px"
    onclick="${() => { emit("select sketch", sketch)}}"
  >

  ${iframe}
    <div class="f6"> ${sketch.fromAccount.name} </div>
    <div class="f7"> ${new Date(sketch.createdAt).toLocaleString()} </div>
    <!-- <div class="f7"> ${sketch.id} </div> -->
  </div>`
  // return html`
  // <a
  //   class="dib pa3 relative animated-positions"
  //   style="top: ${rand(-10, 10)}px; left: ${rand(-10, 40)}px"
  //   href="${window.location.pathname}#${sketch.hash}"
  // >
  // ${iframe}
  //   <div> ${sketch.owner} </div>
  // </a>`
})



module.exports = (collection, state, emit) => html`
  <div class="">
    <!-- <div class="pa2">
      ${slogan({ classes:"absolute"})}
      ${slogan({ classes:"absolute"})}
    </div> -->
    ${collection.status === 0? loading(state) : ''}
    ${showSketches(collection.sketches, state, emit)}
    <!-- <div class="main-heading">
      <div > UNIQUE MAGICAL BROWSER OBJECTS </div>
      <div class="pa4"> get yours now! </div>
      <div> limited time only </div>
    </div> -->
    <!-- <div class="fixed bottom-0 right-0 ma4" onclick=${() => emit('load next')}>
      next
    </div> -->
  </div>
`
