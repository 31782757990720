const html = require('choo/html')

const rand = (max = 1, min = 0) => min + Math.random() * (max - min)
const arrayRand = (arr) => arr[Math.floor(arr.length * Math.random())]

const title = "UNIQUE MAGICAL BROWSER OBJECTS™"
const text = ["limited time only","get yours today", "never before seen",  "support hydra synth", "WEB 8.0 certified", "get yours today", "100% unique", "$$$$", "REAL ART GUARANTEE", "get yours today", "add ART and MAGIC to the world", "render the future", "free shipping",
// "your very own piece of the internet",
//"create the future", "LIVE, COMPUTER GENERATED BROWSER OBJECTS",
"as seen on the internet"]

const buyNowText = ["get yours today", "support hydra synth"]

const fonts = ['garamond', 'baskerville', 'times', 'helvetica']

const colors = ["blue", "green", "yellow", "purple", "pink", "orange", "red", "black"]

// const float =

const generateShadow = () => ` text-shadow:${arrayRand(colors)} ${rand(5, -5)}px ${rand(5, -5)}px ${rand(4, 20)}px;
 text-shadow:${arrayRand(colors)} ${rand(4, -4)}px ${rand(4, -4)}px ${rand(4, 20)}px;
 text-shadow:${arrayRand(colors)} ${rand(4, -4)}px ${rand(4, -4)}px ${rand(4, 20)}px;
 text-shadow:${arrayRand(colors)} ${rand(4, -4)}px ${rand(4, -4)}px ${rand(4, 20)}px;
  transition: color 3s, background-color 3s, max-width 1s, font-size 1s, font-weight 1s; text-shadow: 1s;
/*  color:${arrayRand(colors)}; */
/*  font-family: cursive;*/
 font-weight: 400;
/* font-weight:${rand(200, 1000)};*/
 `

 const randomFont = () => arrayRand(fonts)

const generateStyle = () => `color:${arrayRand(colors)};
               ${generateShadow()}
               font-weight:${rand(200, 1000)};
               font-size:${rand(16, 18)}px;
                max-width: ${rand(200, 200)}px;
               background:${arrayRand(colors)};
               transform: translate: 50% 50%;
               transform: rotate(${rand(-20, 20)}deg);`

  const generateLoadingStyle = () => `color:${arrayRand(colors)};
               ${generateShadow()}
               font-weight:${rand(200, 1000)};
               /*font-size:${rand(16, 18)}px;*/
               /* max-width: ${rand(200, 200)}px; */
               background:${arrayRand(colors)};
               transform: translate: 50% 50%;
               transform: rotate(${rand(-20, 20)}deg);`


const slogan = ({_text =  arrayRand(text), classes="dib pa1 ma3 br-pill ph3 tc", styles=""} = {}) => {
  const style = `${generateStyle()}
                ${styles}
                 `
  return html`<div style=${style} class="${arrayRand(fonts)} ${classes}">

      ${_text === "object"? html`<div style="height:200px; width:200px;background-color:red;"`: _text}
    </div>`
}

const buyButton = ({_text =  arrayRand(buyNowText), classes="dib pa1 ma3 br-pill ph3 tc", styles=""} = {}) => {
  const style = `${generateStyle()}
                ${styles}
                 `
  return html`<div style=${style} class="${arrayRand(fonts)} ${classes}">
      ${ _text}
    </div>`
}


const titleElement = (_text = title) => html`<span style="text-shadow:${arrayRand(colors)} 10px 10px 10px;" class="">
  ${title}
  </span>`


const collection = (number = 30) => {
    const arr = new Array(number).fill(0).map(() => arrayRand(text))
    return arr.map(slogan)
}

const background = () => html`<div class="fixed top-0 left-0 pa2" style="pointer-events: none">
  ${collection(30)}
</div>`
 //  const arr = new Array(30).fill(0).map(() => arrayRand(text))
 // //const arr = text
 //  const slogans = arr.map(slogan)

module.exports = {
  title: titleElement,
  background: background,
  collection: collection,
  slogan: slogan,
  generateStyle: generateStyle,
  generateShadow: generateShadow,
  generateLoadingStyle,
  randomFont: randomFont
}
//
// ///*background-color:${arrayRand(colors)}*/
//   return html`<div class="fixed top-0 left-0 pa2" style="pointer-events: none">
//     ${titleElement()}
//     ${collection(30)}
//   </div>`
// }
