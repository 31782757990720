const html = require('choo/html')
const Component = require('choo/component')


module.exports = class ClickableIframe extends Component {
  constructor (id, state, emit) {
    super(id)
    this.local = state.components[id] = {}
  }

  load (element) {
    this.element = element
  //  console.log('loading element')
  //  console.log('content window', element.contentWindow, this.onclick)
    element.contentWindow.onclick = () => {
    //  console.log('clicked')
      this.onclick()
    }
  //  console.log('click handler', this.onclick)
  //  console.log(  element.contentDocument)
      element.contentWindow.onload = () => {
      //  console.log('page laoded')
        element.contentDocument.html.style.cursor = "pointer"
      }
    element.contentDocument.body.style.cursor = "pointer"
  }

  update ({ src, onclick }) {
//    console.log('updating')
   if(src !== this.element.src) {
    this.onclick = onclick
    this.element.src = src
    this.element.contentWindow.onload = () => {
  //    console.log('page loded')
      element.contentDocument.html.style.cursor = "pointer"
    }
  //  this.element.onload=${() => { console.log('loaded2222!!')}}
    this.element.contentWindow.onclick = () => {
  //    console.log('clicked 2')
      this.onclick()
    }
  }
    return false
  }

  onLoad (el) {
  //  console.log(el, this, 'loaded')
    el.target.contentWindow.onclick = this.onclick
  }

  createElement ({ src, classString, style, width, height, onclick}) {
    this.onclick = onclick
    const new_style_element = document.createElement("style");
    new_style_element.textContent = "body, html { cursor: pointer; }"
    // onload = ${() => console.log(iframe, iframe.contentDocument)}
    const iframe =  html`<iframe src=${src}
      class=${classString}
      width=${width}
      height=${height}
      onload=${this.onLoad.bind(this)}
      style=${style}></iframe>`
    iframe.addEventListener("load", () => {
    //  console.log('iframe loaded!')
      iframe.contentDocument.head.appendChild(new_style_element);
    })
    return iframe
  }
}
