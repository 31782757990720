const html = require('choo/html')
const Component = require('choo/component')
const {title, slogan } = require('./slogans.js')


module.exports = class AnimatedSlogan extends Component {
  constructor (id, state, emit) {
    super(id)
    this.local = state.components[id] = {}
  }

  load (element) {
    // console.log('rendering with cycletime', this.cycleTime)
    const ct = this.cycleTime
    this.interval = setInterval(() => {
      this.rerender()
    }, ct + Math.random()*ct)
  }

  unload () {
    clearInterval(this.interval)
  }

  update() {
    return false
  }
  createElement (cycleTime = 2000, el = slogan) {
    // console.log('rendering')
    this.cycleTime = cycleTime
    return html`<div>${el()}</div>`
  }
}
