const html = require('choo/html')
var raw = require('choo/html/raw')
const AnimatedSlogan = require('./animated-slogan.js')

const loading = require('./loading.js')
const prism = require('prismjs')
//bg-black pv1 fr br-pill  white db ma1 shadow
//  <div class="pr5-ns w4-ns mw7 w-100 flex-auto-ns">
let renderCount = 1
module.exports = (viewer, emit, state) => {
  console.log('VIEWER', viewer)
  const width = window.innerWidth > 860 ? Math.min(window.innerWidth / 2, window.innerHeight - 120) : window.innerWidth - 30
  const iframe = () => {
    const highlighted = prism.highlight(viewer.selected.code, prism.languages.javascript, 'javascript')
    // const buyNow = state.cache(AnimatedSlogan, 1).render(3000, () => {
    //   renderCount++
    //   const style = generateStyle()
    //   return html`<div class="w4 pa2 h-100 ba b2 pointer br-pill dim tc ${randomFont()}" onclick=${() => emit("show info")} style="${style}"> ${renderCount % 2 == 0 ? 'support hydra synth' : 'get yours here!'}  </div>`
    // })
    return html`
<div class="flex flex-column justify-center">
      <iframe
        src=${viewer.selected.url}
        class=""
        style="width:${width}px;height:${width}px;transition:width 1s, height 1s;"

      >
      </iframe>
</div>
 <div class="flex flex-column pa2 justify-center items-start mw-100">

    <div class=" f5 pa2 frame shadow serif ma2">
      <div>  ${viewer.selected.id} </div>
      <div class=""> made possible by
      <span class="f3 b dim pointer" onclick=${() => emit('show user collection', viewer.selected.fromAccount)}>${viewer.selected.fromAccount.name} </span></div>
      <div> ${new Date(viewer.selected.createdAt).toLocaleString()} </div>
    </div>
    <div class="pa3 mw6 frame shadow overflow-y-auto mv2" style="max-height:${window.innerHeight * 0.7}px">
      <pre class="f6 serif text-shadow">${raw(highlighted)}</pre>

      <!--pre class="f5 serif text-shadow"> ${viewer.selected.code} </pre-->
    </div>
    <div class="absolute pointer" style="top: 15px; right: 15px;" onclick=${()=> emit("show info")}>
    ${state.cache(AnimatedSlogan, 2000).render()}
    </div>
    <button class="f7" title="open in editor" onclick="${
      () => window.open(`https://hydra.ojack.xyz/?code=${btoa(
            encodeURIComponent(viewer.selected.code)
          )}`)
    }">open in editor</button>
    <!-- <div onclick=${() => emit('clear sketch')} class="dib ph4 pv1 w4 black ma1 shadow dim pointer"> SHOW Gallery </div> -->
  </div>
`
  }
  //  ${viewer.sketch === null ? '' : iframe()}
  let content = ''
  if (viewer.status === 2) {
    content = 'the UNIQUE MAGICAL BROWSER OBJECT at this link does not exist yet. create more UNIQUE BROWSER OBJECTS by donating to hydra at _______'
  } else if (viewer.status === 1) {
    content = iframe()
  } else {
    content = loading(state)
  }
  return html`
  <!-- <div class="flex flex-column h-100"> -->
    <div class="w-100 flex items-center justify-center flex-auto flex-column flex-row-l pa2 overflow-y-auto">
      ${content}
    </div>

  `
}

// <!-- <div class="pv3 flex">
//   <!-- <div class="bg-white ph4 pv1 fr br-pill  black db ma1 shadow dim pointer" onclick=${() => emit('clear sketch')}> SHOW MOST RECENT </div> -->
//   <!-- <div class="dib flex-auto f4 pa2">$$$ UNIQUE MAGICAL BROWSER OBJECTS $$$</div> -->
//   <div onclick=${()=> emit('clear sketch')} class="dib ph4 pv1  black ma1 shadow dim pointer"> SHOW Gallery </div>
//   <a href="https://opencollective.com/hydra-synth/contribute/unique-browser-object-25415" target="_blank" ><div class="dib bg-yellow ph4 pv1  black ma1 shadow dim pointer"> GET YOURS TODAY </div></a>
// </div> -->
// <!-- </div> -->
