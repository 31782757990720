const html = require('choo/html')
const devtools = require('choo-devtools')
const choo = require('choo')
const store = require('./store.js')
const collection = require('./views/collection.js')
const viewer = require('./views/viewer.js')
const { background } = require('./views/slogans.js')
//const { entrypoint, entrypointBase } = require('./config.js')
const config = require('./config.js')
const info = require('./views/info.js')
const navContainer = require('./views/bottom-nav.js')
const footer = require('./views/footer.js')

const baseRoute = '/index.html'


// const entrypoint = process.env.BASE_URL
// const entrypointBase = process.env.SECONDARY_PATH

const { entrypoint, entrypointBase } = config[process.env.NODE_ENV]
console.log('LOADED ENV', process.env.BASE_URL, process.env.NODE_ENV, entrypoint, entrypointBase)


//   ${state.selected === null ? collection(state, emit) : viewer(state.selected, emit)}
const mainView = (type) => (state, emit) => {
  const nav =  html`${state.views.gallery.offset == 0 ? '' : html`<div class="w3 pointer dim ma2" onclick=${() => emit('load prev')}> prev </div>`}
    ${state.views.gallery.offset + state.views.gallery.limit <= state.db.entries.length ? html`<div class="w3 pointer dim ma2" onclick=${() => emit('load next')}> next </div>  `:''}`
//    const nav = 'hi'
  const content = html`
      ${collection(state.views[type], state, emit)}
      <div class="w3 fr mb5 absolute bottom-0 right-0">
        ${navContainer(nav)}
      </div>
`
  return wrapper(content, state, emit)
}

const app = choo({ hash: true })
app.use(devtools())
app.use(store)
app.route(`${entrypoint}/create`, landingView)
app.route(`${entrypoint}/collection/:user`, mainView('collection'))
//app.route(`${entrypoint}/collection`, mainView) // gallery view but for a specific user

app.route(`${entrypoint}/viewer/:id`, individualView)
app.route(`${entrypoint}`, mainView('gallery'))
app.route(`${entrypoint}/*`, mainView('gallery'))

app.route(`${entrypointBase}/create`, landingView)
app.route(`${entrypointBase}/collection/:user`, mainView('collection'))
//app.route(`${entrypointBase}/collection`, mainView) // gallery view but for a specific user

app.route(`${entrypointBase}/viewer/:id`, individualView)
app.route(`${entrypointBase}`, mainView('gallery'))
app.route(`${entrypointBase}/*`, mainView('gallery'))
app.mount('body')


const wrapper = (content, state, emit) => html`
<body class="w-100 h-100 i helvetica f3 gradient" style="color:#15865d;line-height:1.1;letter-spacing:0.03em/*background:pink;*/">
  <div class="overflow-y-auto flex flex-column" style="height:${window.innerHeight-60}px">${content}</div>
  ${footer(state, emit)}
</body>
`

//class="overflow-y-auto flex justify-center"
// ${landing(state)}
function landingView (state, emit) {
  //style="background: linear-gradient(#ff3267, #00fff0);"
  const content =  html`<div class="flex justify-center">${info(state,emit)}</div>`

  return wrapper(content, state, emit)

  // return html`
  //   <body class="w-100 h-100 i helvetica f3 gradient" style="/*background:pink;*/">
  // //     ${background(state)}
  // //     ${collection(state.views.gallery, state, emit)}
  // //   </body>
  // // `
}

  // ${landing(state)}

function individualView (state, emit) {
  const nav = html`<div onclick=${()=> emit('clear sketch')} class="dib ph4 pv1 black ma1 dim pointer"> back to gallery </div>`

  const content = html`${viewer(state.views.viewer, emit, state)}`


  return wrapper(content, state, emit)

}
