module.exports = {
  development: {
    entrypoint: 'index.html',
    entrypointBase: 'sdf'
  },
  production: {
  // entrypoint: 'index.html'
    entrypointBase: '/umbo',
    entrypoint: ''
  }
  // entrypoint: ''
}
