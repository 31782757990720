const html = require('choo/html')
const AnimatedSlogan = require('./animated-slogan.js')
const {title, slogan, generateLoadingStyle, randomFont } = require('./slogans.js')

module.exports = (state) => {
    const buyNow = state.cache(AnimatedSlogan, 'loading').render(300, ()=> {
        const style = generateLoadingStyle()
        return html`<div class="w4 pa2 h-100 ba b2 pointer br-pill dim tc ${randomFont()}"  style="${style}"> loading unique magical objects....  </div>`})
  return html`<div class="fixed w-100 h-100 f1 flex items-center justify-center">
  ${buyNow}
  </div>`
}
