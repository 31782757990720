const HydraCodeGenerator = require('./code-generator.js')
const debounce = require('./lib/utils.js').debounce
const { getOrderById, getRecentObjects, getOrdersByUser } = require('./lib/queries.js')
const encodeBase64 = (text) => btoa(encodeURIComponent(text))
// const hydraURL = (code) => `https://hydra-lofi.glitch.me?code=${encodeBase64(code)}`
 const hydraURL = (code) => `${window.location.origin}${window.location.pathname.replace('/index.html', '')}/lofi-renderer.html?code=${encodeBase64(code)}`
 const config = require('./config.js')

 const { entrypoint, entrypointBase } = config[process.env.NODE_ENV]
 const generate = require('./code-generator-oj.js')

 const MAX_QUERY = 48 // max number to query from database at a time, make sure a multiple of page limit

module.exports = (state, emitter) => {
//  const codeFactory = new HydraCodeGenerator()
  //setTimeout(() => { window.location.reload() }, 30000) // auto reload
//  console.log(state)
  // views 'gallery'
  state.currentView = 'gallery'
  state.db = {
  //  total: null,
    entries: []
  }
  state.views = {
    gallery: {
      total: null,
      sketches: [],
      limit: 6,
      offset: 0,
      status: 0
    },
    collection: {
      user: null,
      sketches: [],
      offset: 0,
      total: null,
      limit: 6,
      offset: 0,
      status: 0
    },
    viewer: {
      selected: null,
      status: 0
    }
  }
  //state.sketches = getSketches()
  //state.selected = null



  console.log('route is', state.route)
  // getOrderById()
  // .then((data) => console.log(data))
  // .catch((error) => console.error(error))



  window.addEventListener('resize',  debounce(
      () => {
        emitter.emit('render')
      },
      300
    ))
  // state.count = 0
  // emitter.on('increment', function (count) {
  //   state.count += count
  //   emitter.emit('render')
  // })

  // setInterval(() => emitter.emit('render'), 3000)

  function getViewFromRoute() {
    let route1 = state.route.replace(entrypoint, "")
    let route = route1.replace(entrypointBase.replace('/', ''), "")


    route = route.replace(/\\|\//g,'')
    route = route.replace('*', '')
    const separate = route.split(':')
    route = separate[0]
    // console.log('route', state.route, state.params, route, entrypoint, entrypointBase)
  //  state.currentView = hash
    state.currentView = route.replace('index.html', '')
    // console.log(state.currentView)

    if(state.currentView === 'collection') {
      state.views.collection.user = state.params.user
      state.views.collection.status = 0
      getOrdersByUser(state.views.collection.user)
        .then((data) => {
          // console.log('got by user', data)
          // state.views.collection
          state.views.collection.total = data.orders.totalCount
          state.views.collection.entries = data.orders.nodes
          const { limit, offset } = state.views.collection
          const sketches = state.views.collection.entries  //.slice(offset, offset+limit)
          state.views.collection.sketches = sketches.map(getSketch)
          state.views.collection.status = 1
          emitter.emit('render')
        })
        .catch((error) => console.error(error))
    }
  }

  function updatePageState () {
    getViewFromRoute()
    // if(state.currentView === 'gallery') {
    //   // console.log("getting sketch")
    //   //
    //   // state.selected = getSketch(state.params.id)
    //
    // } else if(state.currentView === 'viewer') {
    // //  state.views.viewer.selected = getSketch(state.params.id)
    // }
  }

  getViewFromRoute()

  // load from gallery
  getRecentObjects({ offset: 0, limit: MAX_QUERY })
  .then((data) => {
    console.log(' got gallery! ', data)
    // state.db.totalCount = data.account.orders.totalCount
    // state.db.entries = data.account.orders.nodes
    state.db.totalCount = data.account.orders.totalCount
    state.db.entries = data.account.orders.nodes
    state.db.maxPages = Math.floor(state.db.totalCount / state.views.gallery.limit)
    state.db.pagesLoaded = Math.floor(state.db.entries.length / state.views.gallery.limit)
    // console.log('entries', state.db.entries)
    state.views.gallery.status = 1
    updateSketches()
    emitter.emit('render')
  })
  .catch((error) => console.error(error))



  function updateSketches() {
    const { limit, offset } = state.views.gallery
    const sketches = state.db.entries.slice(offset, offset+limit)
    state.views.gallery.sketches = sketches.map(getSketch)
  }

  emitter.on("load next", () => {
    //state.sketches = getSketches()
    const { limit, offset } = state.views.gallery
    state.views.gallery.offset+= limit
    if(state.views.gallery.offset >= state.db.entries.length) {
      // console.log(state.views.gallery.offset, state.db.pagesLoaded, 'need to load more from database')
      //const offset = Math.floor(state.db.entries.length/MAX_QUERY)
      state.views.gallery.status = 0

      getRecentObjects({ offset: state.db.entries.length, limit: MAX_QUERY })
      .then((data) => {
         console.log(' got gallery! ', data)
        state.db.totalCount = data.account.orders.totalCount
        state.db.entries = state.db.entries.concat(data.account.orders.nodes)
        state.db.maxPages = Math.floor(state.db.totalCount / state.views.gallery.limit)
        state.db.pagesLoaded = Math.floor(state.db.entries.length / state.views.gallery.limit)
        // console.log('entries', state.db.entries)
        state.views.gallery.status = 1
        updateSketches()
        emitter.emit('render')
      })
    } else {
      updateSketches()
      emitter.emit('render')
    }
  })

  emitter.on("load prev", () => {
    //state.sketches = getSketches()
    const { limit, offset } = state.views.gallery
    state.views.gallery.offset-= state.views.gallery.limit
    if(state.views.gallery.offset < 0) state.views.gallery.offset = 0
    updateSketches()
    emitter.emit('render')
  })

  if (state.currentView === 'viewer') {
    if(state.params.id) {
      getOrderById(state.params.id)
        .then((data) => {
          // console.log('got by id', data.order.toAccount.slug)
          if(data.order.toAccount.slug === "hydra-synth") {
          state.views.viewer.selected = getSketch(data.order)
          state.views.viewer.status = 1
          emitter.emit('render')
        } else {
        throw new Error('not in hydra-synth')
      }
        })
        .catch((error) => {
          console.warn(error)
          state.views.viewer.status = 2
          emitter.emit('render')
        })
    }
  }




  function getSketch(order) {
  //  const code = codeFactory.generate2(order.id)
    const code = generate(order.id)
    const url = hydraURL(code)
    return Object.assign({}, order, { code:code, url: url })
  }

//updatePageState()

  emitter.on('navigate', () => {               // 2.
//   console.log(`Navigated to ${state.route}`, state, window.location) // 3.

  updatePageState()
 })



  emitter.on("select sketch", (sketch, index) => {
    // console.log("selecting", sketch, index, state)
    state.views.viewer.selected = sketch
    state.views.viewer.status = 1
  //  window.history.pushState({}, '', "${window.location.pathname}#${sketch.hash}")
    emitter.emit('pushState', `${window.location.pathname}#viewer/${sketch.id}`)
  })

  emitter.on("show user collection", (user) => {
    state.views.viewer.selected = null
    state.views.viewer.status = 0
    emitter.emit('pushState', `${window.location.pathname}#collection/${user.slug}`)
  })

  emitter.on('show info', () => {
    // console.log('showing info')
    emitter.emit('pushState', `${window.location.pathname}#create`)
  })

  emitter.on("clear sketch", () => {
  //  console.log("selecting", sketch, index, state)
    state.views.viewer.selected = null
    state.views.viewer.status = 0
  //  console.log(window.location, )
    emitter.emit('pushState', `${window.location.pathname}`)
  //  emitter.emit('render')
  })

  // function getSketch(hash) {
  //   const code = codeFactory.generate2(hash)
  // const url = hydraURL(code)
  // return {
  //   code: code,
  //   hash: hash,
  //   url: url,
  //   owner: "<contributer name>",
  //   date: new Date()
  // }
  // }

  // function getSketches(num = 5) {
  //   return new Array(num).fill()
  //     //.map(() => randomHex(6))
  //     .map(() => {
  //       const hash = randomHex(6)
  //       return getSketch(hash)
  //   })
  // }
//)
}

//const rand = (min, max) => min + Math.random() * (max - min)
function randomHex (size) {
  return [...Array(size)].map(() => Math.floor(Math.random() * 16))
}
