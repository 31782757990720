/*

QUERY LANG TO DO:

// get most recent transactions
// get transactions by a specific user
// check whether there is transaction with a specific id


FRONT END:
// show date and user next to sketch
//

*/

const { request, gql } = require('graphql-request')

const url = 'https://api.opencollective.com/graphql/v2/1278c9c3ce0a62a6841f76f6ce680ecc52447b14'
const tier = "88rzownx-l9e50pxy-8rb6ymvb-dgk7j43a"
// const slug = "babel"
const slug = "hydra-synth"
//const variables = gql`{"slug": "babel"}`

const orderQuery = (id = "eejoxl3a-z45w9pao-5rrqy870-mgkbrvdn") =>  `{
    order(order: {id: "${id}"}){
        id
        createdAt
        legacyId
        amount {
          value
          currency
        }
        quantity
        fromAccount {
           name
          slug
        }
        toAccount {
          slug
        }
      }
  }`

  

  // const orderQuery = (id = "eejoxl3a-z45w9pao-5rrqy870-mgkbrvdn") =>  `{
  //   order(order: {id: "eejoxl3a-z45w9pao-5rrqy870-mgkbrvdn"}){
  //       id
  //       createdAt
  //       legacyId
  //       amount {
  //         value
  //         currency
  //       }
  //       quantity
  //       fromAccount {
  //          name
  //         slug
  //       }
  //       toAccount {
  //         slug
  //       }
  //     }
  // }`

  // const orderQuery = (id = "eejoxl3a-z45w9pao-5rrqy870-mgkbrvdn") =>  `{
  //   transactions(id: "${id}"){
  //       id
  //       createdAt
  //       legacyId
  //       amount {
  //         value
  //         currency
  //       }
  //       quantity
  //       fromAccount {
  //          name
  //         slug
  //       }
  //       toAccount {
  //         slug
  //       }
  //     }
  // }`
// const userQuery = (user) => `
//   {
//     transactions(limit: 100, type: CREDIT, account: { slug: "${slug}"}, searchTerm: "${user}") {
//       totalCount
//       nodes {
//         id
//         createdAt
//         fromAccount {
//           slug
//           name
//         }
//       }
//     }
//   }
// `

const userQuery = (user) => `
  {
    orders(limit: 100, account: { slug: "${slug}"}, searchTerm: "${user}") {
      totalCount
      nodes {
        id
        createdAt
        fromAccount {
          slug
          name
        }
      }
    }
  }
`

// const recentObjectsQuery = (offset = 0, limit=10) => `{
//   tier(tier: { id: "${tier}" }) {
//     name
//     slug
//     orders(limit: ${limit}, offset: ${offset}) {
//       totalCount
//       nodes {
//         id
//         createdAt
//         tier {
//           slug
//         }
//         fromAccount {
//           slug
//           name
//         }
//         toAccount {
//           slug
//         }
//       }
//     }
//   }
// }`

// const recentObjectsQuery = ({offset = 0, limit = 12} = {}) => `{
//   account(slug: "${slug}" ) {
//     name
//     slug
//     orders(limit: ${limit}, offset: ${offset}) {
//       totalCount
//       nodes {
//         id
//         createdAt
//         tier {
//           slug
//         }
//         fromAccount {
//           slug
//           name
//         }
//         toAccount {
//           slug
//         }
//       }
//     }
//   }
// }`

const recentObjectsQuery = ({offset = 0, limit = 12} = {}) => `{
  account(slug: "${slug}" ) {
    name
    slug
    orders(limit: ${limit}, offset: ${offset}) {
      totalCount
      nodes {
        id
        createdAt
        fromAccount {
          slug
          name
        }
        toAccount {
          slug
        }
      }
    }
  }
}`

// const recentObjectsQuery = ({offset = 0, limit = 12} = {}) => `{
//   account(slug: "${slug}" ) {
//     name
//     slug
//     transactions(limit: ${limit}, offset: ${offset}, type: CREDIT) {
//       totalCount
//       nodes {
//         id
//         createdAt
//         fromAccount {
//           slug
//           name
//         }
//         toAccount {
//           slug
//         }
//       }
//     }
//   }
// }`

module.exports = {
  getOrderById: (id = "eejoxl3a-z45w9pao-5rrqy870-mgkbrvdn") => request(url, orderQuery(id)),
  getRecentObjects: (offset, limit = 20) =>  request(url, recentObjectsQuery(offset, limit)),
 getOrdersByUser: (user) => request(url, userQuery(user))
// getOrdersByUser: (user) => console.log(userQuery(user))
}
