const html = require('choo/html')
const {generateStyle, generateShadow, slogan, randomFont } = require('./slogans.js')
const AnimatedSlogan = require('./animated-slogan.js')

// style=${/*baseStyle()}
const span = (text) => html`<span class="pill " style=${generateShadow()}>${text}</span>`

//const span = (text) => html`<span class="pill ">${text}</span>`
// const baseStyle = generateShadow
const baseStyle = () => ''

// ${span('100% real art guarantee')}, each contribution
// const span = (text) => html`<span class="pill ">${text}</span>`
// For each donation, a an ${span(`extraordinary super-advanced post-blockchain post-scarcity computer algorithm.*`)}
// conjures a post-blockchain algorithm, that is able to render
//extraordinary super-advanced
//not *.GIFs, *.JPGs, *.MP4s, or *.OBJs, only ${span('real UMBO™')}
// The generated art objects are  ${span(`rendered live on your device's graphics card`)} by a ${span(`hyper-magical post-blockchain internet algorithm.*`)}
//   <p style=${baseStyle()} class=""> Create your very own ${span('UNIQUE MAGICAL BROWSER OBJECT™')}, live ${span('before your very eyes')} !</p>
// <p>${span('But the magic does not stop there.')}

// <p style=${baseStyle()}> When you make a donation on this page, our ${span(`magical post-blockchain internet algorithm.*`)} will generate a unique art object in your honor.

// <p style=${baseStyle()}> Add ${span('more art and more magic')} to the world!
// Create your very own ${span('UNIQUE MAGICAL BROWSER OBJECT™')}, live ${span('before your very eyes')} !
// Your ${span('UMBO™')} will be displayed in the ${span('UMBO™ gallery')}  along with your name and the code used to generate it. In addition, a ${span('special, exclusive, collection space')} will be digitally conjured in your name,  where you can view all of your magical art objects and share them with your friends. </p>
// ${state.cache(AnimatedSlogan, 6).render()}
// ${state.cache(AnimatedSlogan, 7).render()}
module.exports = (state, emit) => {
  return html`<div class="relative mw7 ph2 f4" >
  <div class="h3" style="left:-120px;pointer-events:none">
  ${state.cache(AnimatedSlogan, 2000).render()}

  </div>


 <p>
   Each donation to <a target="_blank" href="https://opencollective.com/hydra-synth">hydra community fund</a> generates a ${span('UNIQUE MAGICAL BROWSER OBJECT™')} that has never before existed in all of history.
   The donations support <a target="_blank" href="https://hydra.ojack.xyz">hydra video synthesizer</a> and <a target="_blank" href="https://hydra.ojack.xyz/grants/">hydra community microgrants.</a> 
   </p>
  <div class="f5">
  <h4>  HOW TO PARTICIPATE </h4>
  <p> When you make a contribution of $5 or more to hydra (below), the transaction ID will be used to generate a UNIQUE MAGICAL BROWSER OBJECT™ in your name.  Your UMBO™ will appear at the top of the main gallery at <a href="https://uniquemagicalbrowserobjects.com">https://uniquemagicalbrowserobjects.com</a>, along with a personalized link to the artwork and the source code that generates the artwork.
 You can also participate anonymously by choosing "Incognito" when donating.
  <p>There is no limit to the number of UMBO™ that you can create!
  Each new object adds MORE ART and MORE MAGIC to the world.</p>
  <p> No GIFs, No JPEGS, No  *.OBJ, No .mp4s, No ETH! only real live, browser-rendered art objects! ${span('ACT NOW')} and support diy art software, open source communities, and ${span(`DIGITAL ABUNDANCE`)}. </p>

  </p>


</div>

<!-- <p>ACT NOW AND BE A PART OF WEB 8.0 !!!! Limited time only.</p> -->
<div class="h4" style="pointer-events:none">${state.cache(AnimatedSlogan, 3).render()}${state.cache(AnimatedSlogan, 4).render()}</div>
  <iframe src="https://opencollective.com/embed/hydra-synth/donate?useTheme=false" style="width: 100%; height:600px;" ></iframe >
<div class="f5 pb6">
 <h4>About Hydra</h4>

<p style=${baseStyle()}><a target="_blank" href="https://hydra.ojack.xyz">Hydra</a> is a browser-based video synthesizer and live coding platform started by olivia jack in 2018. Its API is inspired by analog video synthesis, in which multiple visual sources (oscillators, cameras, application windows, other connected windows) can be transformed, modulated, and composited via combining sequences of functions. All volunteer-run and <a href="https://github.com/ojack/hydra">open-source</a>, the project has a vibrant and growing community of users and contributers around the world.  The donations on this page go towards developing and maintaining hydra, supporting <a target="_blank" href="https://hydra.ojack.xyz/grants/">hydra community microgrants,</a> as well as hosting community events and coding + video art workshops.</p>

<h4>TECHNICAL DETAILS</h4>

<p style=${baseStyle()}>Each contribution on this site generates a record of that transaction in the Open Collective database. The id of that transaction is used as a "seed" for a computer-generated sketch created using hydra.
An infinite number of sketches can be created in this way. There is no blockchain involved, and the only transactions are the donations to hydra that are recorded on Open Collective. All processing and rendering happens on your own device, based on the generated seed (there is no server). </p>

<p>UNIQUE MAGICAL BROWSER OBJECTS™ was created by <a target="_blank" href="https://ojack.xyz">olivia jack</a>, in collaboration with Ale Colmenotti, Canek Zapata, Flor de Fuego, Mark DeNardo, and the hydra community.</p>
<p>
Tech involved: <a target="_blank" href="https://github.com/ojack/hydra-synth">hydra synth</a>, <a target="_blank" href="https://github.com/alecominotti/hydracodegenerator">hydra code generator</a>, <a target="_blank" href="https://www.choo.io/">choo.io</a>, <a target="_blank" href="https://docs.opencollective.com/help/contributing/development/api">Open Collective GraphQL API</a>.
</p>

Inspiration from fungible tokens, Walter Mercado, and Ron Popeil. For questions, please email <a target="_blank" href="mailto:hydra.video.synth@gmail.com">hydra.video.synth@gmail.com</a>
</p>
<p>
(c) 2021 All UNIQUE MAGICAL BROWSER OBJECTS are licensed under a <a target="_blank" href="http://creativecommons.org/licenses/by-nc-sa/3.0/">Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.</a>
</p>
  </div>
</div>
  `
}
// (Special thanks to Nai, Rosa, Casey, Kirk, and Warren for helping me process my thoughts about art, NFTs, and community funding.)</p>

// <p>
  // The real, 100% certified magical artworks in this gallery are generated live in your browser, before your very eyes.  </p>
 //The UMBO you see here have never before existed in all of history.
//   are 100% real art ththat is activated by generous contributions from </p>
//// Each donation generates a UNIQUE MAGICAL BROWSER OBJECT™ that has never before existed in all of history.
  // Each UNIQUE MAGICAL BROWSER OBJECT in this gallery was generated by a live, magical computer algorithm.//
// NO GIFS, JPGS, OR MP4s, only real live UMBO™. </p>
// <p>Each donation adds more ART and MAGIC to the world!
// WEB 8.0 is about DIGITAL ABUNDANCE, open-source community software.
