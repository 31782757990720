const html = require('choo/html')

// border bt bw1
module.exports = (content) => {
  return html`<div class="h3 fixed bottom-0 right-0 mb5 f5">
    ${content}
  </div>`
}


// <div class="marquee f1"> UNIQUE MAGICAL BROWSER OBJECTS</div>
//   <div class="marquee2 f1"> UNIQUE MAGICAL BROWSER OBJECTS</div>
// <marquee behavior="scroll" direction="left" scrollamount="2"  class="bottom-0 fixed f1 flex-auto">
//
//     ${title()}
//
// </marquee>
// <div class="marquee w-100 f1"> ${title()}</div>
//   <div class="marquee2 w-100 f1"> ${title()}</div>
