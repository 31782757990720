// import hydra function information (not currently used)
// const hydraFunctions = require("./lib/hydra-functions.js");
require('./seedrandom.js')

const extraCode = `
  speed = 0.1
`

const useSeed = false// turn off seed for dev purposes

module.exports = class CodeGenerator {
  constructor() {
    this.minValue = 1; // min argument value possible
    this.maxValue = 10; // max argument value possible
    this.minBoundedValue = 0;   // min argument value possible for small range
    this.maxBoundedValue = 1; // max argument value possible for small range
    this.minFunctions = 2; // min amount of functions possible
    this.maxFunctions = 5; // max amount of functions possible
    this.mouseProbability = 0 // %

    this.sources = [
      "gradient",
      "osc",
      "noise",
      "shape",
      "voronoi",
    //  "feedback"
    ];
    this.colorTransforms = [
      "invert",
      "contrast",
      //"brightness",
      //"luma",
      //"thresh",
      "color",
      "saturate",
      "hue",
      "colorama"
    ];
    this.coordTransforms = [
      "rotate",
      "scale",
      "pixelate",
      "repeatX",
      "repeatY",
      "kaleid",
      "scrollX",
      "scrollY"
    ];
    this.modulateTransforms = [
      "modulate",
      "modulateHue",
      "modulateKaleid",
      "modulatePixelate",
      "modulateRotate",
      "modulateScale",
      "modulateRepeat",
      "layer"
      //"modulateRepeatX",
      //"modulateRepeatY",
      //"modulateScrollX",
      //"modulateScrollY",
    ];
    this.mouseParameters = ["() => (mouse.x/width + 0.00001) * 0.001", "() => (mouse.y/height + 0.00001) * 0.001"]
    this.allTransforms = this.colorTransforms.concat(this.coordTransforms).concat(this.modulateTransforms);
  }


  generate2(seed){
    if(useSeed) Math.seedrandom(seed)
    let functionsAmount = this.getRandomBetween(this.minFunctions, this.maxFunctions, false);
    let codeString = "";
    codeString += extraCode
    // Generate a source
    codeString += this.generateElementFrom(this.sources)
    // Generate some functions
    for (var i = 0; i < functionsAmount; i++) {
      if((i == functionsAmount-1) && (this.mouseProbability != 0)){ // last iteration and mouse was not generated yet
        this.mouseProbability = 100
      }
      codeString += "\n"
      codeString += this.generateElementFrom(this.allTransforms)
    }
    // codeString += "\n"
    //
    // codeString += `.mask(shape(4, 0.6).modulateScale(osc(3, 1), 0.5, 1))`;
    // Add .out()
    codeString += "\n.out(o0)"

  //  this.mouseProbability = 50 // reset it back
    // Return finished code/sketch
    return codeString
  }

  getRandomBetween(min, max, decimal){
    if(decimal){
      return (Math.random() * (max - min) + min).toFixed(2)
    }
    else{
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }

  genRandomValue(bounded){ // random number between this.min and this.max
    // if(this.getRandomBetween(0, 100, false) <= this.mouseProbability){
    //   this.mouseProbability = 0
    //   return this.mouseParameters[this.getRandomBetween(0, this.mouseParameters.length-1, false)]
    // }else{
      if(bounded){ // bounded random number, for when smaller values are needed
        return this.getRandomBetween(this.minBoundedValue, this.maxBoundedValue, true)
      }
      else{
        return this.getRandomBetween(this.minValue, this.maxValue, false)
      }
  //  }
  }

  generateElementFrom(array){
    let pos = this.getRandomBetween(0, array.length-1, false);
    let element = array[pos]
    return this[element](); // executes method called [element]
  }


  gradient(){
    return "gradient(" + this.genRandomValue(false) + ")"
  }
  osc(){
    return "osc(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }

  osc(){
    return "osc(" + this.genRandomValue(false) + ", 0.1, " + this.genRandomValue(false) + ")"
  }

  noise(){
    return "noise(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }
  shape(){
    return "shape(" + (this.genRandomValue(false)+2) + ", " + this.genRandomValue(true) + ", " + this.genRandomValue(true) + ")"
  }
  voronoi(){
    return "voronoi(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ", " + this.genRandomValue(true) + ")"
  }

  feedback() {
    return "src(o0)"
  }



  invert(){
    return ".invert(" + this.genRandomValue(false) + ")"
  }
  contrast(){
    return ".contrast(" + this.genRandomValue(false) + ")"
  }
  brightness(){
    return ".brightness(" + this.genRandomValue(true) + ")"
  }
  luma(){
    return ".luma(" + this.genRandomValue(true) + ")"
  }
  thresh(){
    return ".thresh(" + this.genRandomValue(true) + ", " + this.genRandomValue(true) + ")"
  }
  color(){
    return(".color(" + this.genRandomValue(true) + ", " + this.genRandomValue(true) + ", " + this.genRandomValue(true) + ")")
  }
  saturate(){
    return ".saturate(" + this.genRandomValue(false) + ")"
  }
  hue(){
    return ".hue(" + this.genRandomValue(true) + ")"
  }
  colorama(){
    return ".colorama(" + this.genRandomValue(false) + ")"
  }


  rotate(){
    return ".rotate(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }
  scale(){
    return ".scale(" + this.genRandomValue(true)  + ", " + this.genRandomValue(true) + ", " + this.genRandomValue(true) + ")"
  }
  pixelate(){
    return ".rotate(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }
  repeatX(){
    return ".repeatX(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }
  repeatY(){
    return ".repeatY(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }
  kaleid(){
    return ".kaleid(" + this.genRandomValue(false) + ")"
  }
  scrollX(){
    return ".scrollX(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }
  scrollY(){
    return ".scrollY(" + this.genRandomValue(false) + ", " + this.genRandomValue(false) + ")"
  }

  layer() {
    return ".layer(" + this.generateElementFrom(this.sources) + ".luma(" + this.genRandomValue(false) + "))"
  }

  modulate(){
    return ".modulate(" + this.generateElementFrom(this.sources) + ", " + this.genRandomValue(true) + ")"
  }
  modulateHue(){
    return ".modulateHue(" + this.generateElementFrom(this.sources) + ", " + this.genRandomValue(true) + ")"
  }
  modulateKaleid(){
    return ".modulateKaleid(" + this.generateElementFrom(this.sources) + ", " + this.genRandomValue(true) + ")"
  }
  modulatePixelate(){
    return ".modulatePixelate(" + this.generateElementFrom(this.sources) + ", " + this.genRandomValue(true) + ")"
  }
  modulateRotate(){
    return ".modulateRotate(" + this.generateElementFrom(this.sources) + ", " + this.genRandomValue(true) + ")"
  }
  modulateScale(){
    return ".modulateScale(" + this.generateElementFrom(this.sources) + ", " + this.genRandomValue(true) + ")"
  }
  modulateRepeat(){
    return ".modulateRepeat(" + this.generateElementFrom(this.sources) + ", " + this.genRandomValue(true) + ")"
  }






  generate(hex) {
    const paramMax = 10;
    const sourceIndex = hex[0] % this.sources.length;
    const param = (paramMax * hex[0]) / this.sources.length;
    //const param = `() => mouse.x/width`
    let codeString = `${this.sources[sourceIndex]}(${param})`;

    const transform = this.colorTransforms.concat(this.coordTransforms);
    for (var i = 1; i < hex.length / 2; i++) {
      const param = hex[i * 2 + 1];
      codeString += `.${transform[hex[i * 2]]}(${param * paramMax / 0.5})`;
    }

    codeString += `.mask(shape(4, 0.9).modulateScale(osc(3, 1), 0.5, 1))`;
    codeString += `.scale(() => 0.5 + 4 * mouse.x/width)`;
    codeString += `.out()`;
    return codeString;
  }
};
